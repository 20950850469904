import Button from "react-bootstrap/Button";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Formik } from "formik";
import * as yup from "yup";
import logo from "../Images/logo.png";
import { careerRegister, getDepartmentWiseQ, getDepartmentDropDown } from "../reducers/commonReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  number: yup.string().min(10).max(10).required(),
  resume: yup.mixed().required(),
  department: yup.string().required(),
  expectedSalary: yup.string().required().max(5),
  town: yup.string().required().max(15),
  // reqQuestions: yup.array().of(
  //   yup.object().shape({
  //     question:yup.string(),
  //     status: yup.string().required("This question is required"),
  //   })
  // ),
});

function Career() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getDepartmentDropDown())
  }, [])

  const { departmentDropDownList, departmentWiseQList } = useSelector((state) => state.commonReducer);
  return (
    <>
      <div className="login-page bg-light">
        <div className="container">
          <div className="col-lg-10 offset-lg-1">
            <div className="bg-white shadow rounded p-5">
              <Row className="text-center">
                <img className="width-auto mb-3 m-auto logoForm" src={logo} />
                <h3 className="mb-4">
                  Start Career With <b>Shyam-G</b>
                </h3>
              </Row>
              <Formik
                enableReinitialize={true}
                validationSchema={schema}
                onSubmit={async (values, { resetForm }) => {
                const apiResult = await dispatch(careerRegister(values));
                  resetForm({ values: "" });
                  setTimeout(() => {
                    apiResult?.payload?.status === 200 &&
                        navigate("/thankyou-distributor?page=career")
                      }, 1000)
                }}
                initialValues={{
                  name: "",
                  email: "",
                  number: "",
                  resume: "",
                  department: "",
                  expectedSalary:'',
                  town:'',
                  reqQuestions: [],
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  setFieldValue,
                  errors,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <Col md={6} sm={12}>
                        <Form.Group>
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={!!errors.name}
                          />

                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6} sm={12}>
                        <Form.Group>
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                          />

                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={6} sm={12}>
                        <Form.Group>
                          <Form.Label>
                            Mobile Number{" "}
                            <span className="text-secondary">
                              (Country Code Not Required)
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Mobile Number"
                            name="number"
                            value={values.number}
                            onChange={handleChange}
                            isInvalid={!!errors.number}
                          />

                          <Form.Control.Feedback type="invalid">
                            {errors.number}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6} sm={12}>
                        <Form.Group>
                          <Form.Label for="myfile">Resume</Form.Label>
                          <Form.Control
                            id="myfile"
                            type="file"
                            name="resume"
                            onChange={(event) => {
                              const resumeFile = event.target.files[0];
                              setFieldValue("resume", resumeFile);
                            }}
                            isInvalid={!!errors.resume}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.resume}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4} sm={12}>
                        <Form.Label>Select Departments</Form.Label>
                        <Form.Control
                          as="select"
                          name="department"
                          className="form-control"
                          value={values.department}
                          initialValue={values.department}
                          onChange={(e) => {
                            setFieldValue("department", e.target.value);
                            dispatch(getDepartmentWiseQ({id : e.target.value}))
                          }}
                          isInvalid={!!errors.department}
                        >
                          <option value="">Select Departments</option>
                          {
                            !!departmentDropDownList && departmentDropDownList?.map((item, index) => <option key={index} value={item?._id}>{item?.name}</option>)
                          }
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.department}
                        </Form.Control.Feedback>
                      </Col>
                      <Col md={4} sm={12}>
                        <Form.Group>
                          <Form.Label>
                          Town
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Your Town"
                            name="town"
                            value={values.town}
                            onChange={handleChange}
                            isInvalid={!!errors.town}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.town}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={4} sm={12}>
                        <Form.Group>
                          <Form.Label>
                          Expected Salary(Monthly CTC)
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Expected Salary"
                            name="expectedSalary"
                            value={values.expectedSalary}
                            onChange={handleChange}
                            isInvalid={!!errors.expectedSalary}
                          />

                          <Form.Control.Feedback type="invalid">
                            {errors.expectedSalary}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    {
                      !!departmentWiseQList?.reqQuestions && <Row className="mb-3">
                        {
                          departmentWiseQList?.reqQuestions?.map((item, index) => <Col key={index}>
                          <Form.Label className="text-danger">{item}*</Form.Label>
                          <Form.Control
                            as="select"
                            name={`reqQuestions[${index}].status`}
                            className="form-control"
                            value={values.reqQuestions[index]?.status || ""}
                            onChange={(e) => {
                              setFieldValue(`reqQuestions[${index}].status`, e.target.value);
                              setFieldValue(`reqQuestions[${index}].question`, item);
                            }}
                            isInvalid={!!errors.reqQuestions && !!errors.reqQuestions[index]?.status}
                          >
                            <option value="">Please Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                          {errors.reqQuestions && errors.reqQuestions[index]?.status}
                          </Form.Control.Feedback>
                        </Col>)
                        }
                    </Row>
                    }
                    <Button type="submit">Submit</Button>
                  </Form>
                )}
              </Formik>
              {/* <p>
                <b>{careerMsg}</b>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Career;
